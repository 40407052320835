<template>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle="'single-choice-' + item_index" variant="success">{{ item.title }}</b-button>
      </b-card-header>
      <b-collapse :id="'single-choice-' + item_index" accordion="my-accordion" role="tabpanel">
        <b-card-body style="background-color: #F7F7F7">
            <b-form-group
                id="single-choice-content"
                label="Content"
                label-for="single-choice-input-content"
                class="mb-2"
            >
                <b-form-input
                id="single-choice-input-content"
                v-model="item.title"
                type="text"
                />
            </b-form-group>
            <label for="type-section">Type</label>
            <b-input value="Single Choice" disabled />
            <label for="single-answer-section">Answers</label>
            <div id="single-answer-section" style="border: 1px dashed black">
                <div v-for="(option, index) in item.options" :key="item.title+item_index+index" class="d-flex align-items-center p-2">
                    <span style="width: 20px"> {{ index + 1 }} </span>
                    <div class="w-100">
                        <b-form-input
                            id="o-content"
                            type="text"
                            v-model="option.text"
                        />
                        <b-form-checkbox
                            :id="`checkbox-textbox-${index}-${item.title}`"
                            v-model="option.is_checkbox"
                            name="checkbox-textbox"
                            class="mt-1"
                        >
                            Is text box?
                        </b-form-checkbox>
                    </div>
                    <button class="btn" @click="removeAns(index)">
                        <feather-icon icon="XIcon" style="color: red" />
                    </button>
                </div>  
            </div>
            <div class="text-center mt-2">
                <b-button @click="addOption" variant="flat-success" class="btn-primary" size="sm">+ Add option</b-button>
            </div>
            <div class="text-right">
                <b-button @click="confirmDelete" variant="danger" class="btn-primary">Delete</b-button>
            </div>
        </b-card-body>
      </b-collapse>
    </b-card>
</template>
<script>
export default {
    props: {
        item: {type: Object, default: null},
        item_index: {type: Number, default: null}
    },
    methods: {
        addOption(){
            this.item.options.push({text: null});
        },
        removeAns(index){
            this.item.options.splice(index, 1);
        },
        confirmDelete() {
            this.$bvModal
            .msgBoxConfirm("Are you sure ?", {
                title: "Please Confirm",
                size: "md",
                okVariant: "danger",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "outline-secondary",
                hideHeaderClose: true,
                centered: true,
            })
            .then((value) => {
                if (value === true) {
                    this.deleteQuestion();
                }
            });
        },
        deleteQuestion(){
            this.$emit("deleteQuestion", this.item_index)
        }
    }
}
</script>