<template>
  <div class="page-content">
    <page-breadcrumb title="Survey Detail" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="survey">
        <form-generator :model="survey" :schema="survey_form_schema" />
        <content-component :items="survey.questions" />
      </b-card>
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service';
import ContentComponent from './_components/ContentComponent.vue'

export default {
  components:{
    ContentComponent
  },
  data(){
    return{
      survey_form_schema: [
        {
          cols: 12,
          fields: [
            { label: 'Type', field: 'type', input_type: 'select',
              options: [
                {text: "Pronun Survey", value: 1},
                {text: "Breaking Survey", value: 2},
                {text: "Arena Survey", value: 3},
                {text: "Monthly Survey", value: 4},
                {text: "Other Survey", value: 5},
                {text: "Social Plus Survey", value: 6},
                {text: "MANUAL_TRIGGER", value: 7},
              ],
              validate: { required: true },
              disabled: true
            },
            { label: 'Title', field: 'title', input_type: 'text', validate: { required: true } },
            { label: 'Key', field: 'key', input_type: 'text', validate: { required: true } },
            { label: 'Language', field: 'language', input_type: 'select', options: this.$store.getters['language/language_options'], validate: { required: true }},
            { label: 'Subtitle', field: "sub_title", input_type: 'ui-component'},
            { label: 'Reward Type', field: 'reward_type', input_type: 'select', options: [{text: "Token", value: 1}, {text: "Gem", value: 2}]},
            { label: 'Reward Amount', field: 'reward_amount', input_type: 'number', validate: { required: true } },
            { label: 'Start Time', field: 'start_time', input_type: 'datetime', validate: { required: true }, displayCond(model){ return model.type != 4}},
            { label: 'End Time', field: 'end_time', input_type: 'datetime', validate: { required: true }, displayCond(model){ return model.type != 4}},
            { label: 'Send Time', field: 'send_time', input_type: 'datetime', validate: { required: true }, displayCond(model){ return model.type == 5}},
            { label: 'Send Day', field: 'send_time_month_day', input_type: 'select', validate: { required: true }, options: [], displayCond(model){ return model.type == 4}},
            { label: 'Send Hour', field: 'send_hour', input_type: 'select', validate: { required: true }, options: [], displayCond(model){ return model.type == 4}},
          ]
        },
      ],
      survey: null,
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    surveyId() {
      return this.$route.params.survey_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Survey List',
          to: { name: 'survey-list' },
        },
        {
          text: `${this.surveyId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    for(let i = 1; i <= 31; i++){
      this.survey_form_schema[0].fields[10].options.push({text: i, value: i})
    };
    for(let i = 1; i <= 24; i++){
      this.survey_form_schema[0].fields[11].options.push({text: i, value: i})
    };
    this.getSurvey();
  },
  methods:{
    // category
    async getSurvey(){
      this.survey = await service.get({id: this.surveyId});
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.survey),
      });
      this.getSurvey();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.surveyId });
      this.$router.push({ name: "survey-list" });
    },
  }
}
</script>
