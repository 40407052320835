<template>
    <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'range-choice-' + item_index" variant="success">{{ item.title }}</b-button>
        </b-card-header>
        <b-collapse :id="'range-choice-' + item_index" accordion="my-accordion" role="tabpanel">
            <b-card-body style="background-color: #F7F7F7">
                <b-form-group
                    id="single-choice-content"
                    label="Content"
                    label-for="single-choice-input-content"
                    class="mb-2"
                >
                    <b-form-input
                    id="single-choice-input-content"
                    v-model="item.title"
                    type="text"
                    />
                </b-form-group>
                <b-form-group
                    id="range-choice-min"
                    label="Min"
                    label-for="input-min"
                >
                    <b-form-input
                    id="input-min"
                    v-model="item.min"
                    type="number"
                    />
                </b-form-group>
                <b-form-group
                    id="range-choice-max"
                    label="Max"
                    label-for="input-max"
                >
                    <b-form-input
                    id="input-max"
                    v-model="item.max"
                    type="number"
                    />
                </b-form-group>
                <div class="text-right">
                    <b-button @click="confirmDelete" variant="danger" class="btn-primary">Delete</b-button>
                </div>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>
<script>
export default {
    props:{
        item: { type: Object, default: null},
        item_index: {type: Number, default: null}
    },
    methods: {
        confirmDelete() {
            this.$bvModal
            .msgBoxConfirm("Are you sure ?", {
                title: "Please Confirm",
                size: "md",
                okVariant: "danger",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "outline-secondary",
                hideHeaderClose: true,
                centered: true,
            })
            .then((value) => {
                if (value === true) {
                    this.deleteQuestion();
                }
            });
        },
        deleteQuestion(){
            this.$emit("deleteQuestion", this.item_index)
        }
    }
}
</script>