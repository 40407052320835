<template>
    <div>
        <label class="col-form-label" for="question-area">Questions</label>
        <b-card>
            <div v-for="(item, index) in items" :key="index" id="question-area">
                <single-choice v-if="item.type == 1" :item="item" :item_index="index" @deleteQuestion="deleteQuestion" />
                <multiple-choice v-if="item.type == 2" :item="item" :item_index="index" @deleteQuestion="deleteQuestion" />
                <range-choice v-if="item.type == 3" :item="item" :item_index="index" @deleteQuestion="deleteQuestion" />              
            </div>
            <div class="text-center mt-2">
                <b-button @click="isModalShow = true" variant="flat-success" class="btn-primary">+ Add question</b-button>
            </div>
        </b-card>

        <!-- Create question modal -->
        <b-modal
            v-model="isModalShow"
            title="Select question type" hide-footer size="lg" modal-class="modal-primary"
            :no-close-on-backdrop="false"
        >
            <form-generator :model="new_question" :schema="question_form_schema" ref="data_form" />
            <div class="d-flex justify-content-between my-2">
                <b-button variant="danger" @click="close">Cancel</b-button>
                <b-button type="submit" variant="primary" @click="create">Create</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import SingleChoice from './SingleChoice.vue'
import MultipleChoice from './MultipleChoice.vue'
import RangeChoice from './RangeChoice.vue'
const question_form_schema = [
  {
    cols: 12,
    fields: [
        { label: 'Question', field: 'title', input_type: 'text', validate: { required: true } },
        { label: 'Type', field: 'type', input_type: 'select', validate: { required: true },
            options: [
                {text: "Single Choice", value: 1},
                {text: "Multiple Choice", value: 2},
                {text: "Range", value: 3},
            ] 
        },
    ]
  },
];
export default {
    components: {
        SingleChoice,
        MultipleChoice,
        RangeChoice
    },
    props: {
        items: {type: Array, default: () => []}
    },
    data(){
        return {
            type_options: [
                {text: "Single Choice", value: 1},
                {text: "Multiple Choice", value: 2},
                {text: "Range", value: 3},
            ],
            selected_type: null,
            single_choice_default: {type: 1, options: []},
            multiple_choice_default: {type: 2, options: [], max_select: 2},
            range_default: {type: 3, min: null, max: null},
            isModalShow: false,
            new_question: {
                title: null,
                type: null
            },
            question_form_schema
        }
    },
    methods: {
        create(){
            let is_valid = this.$refs.data_form.validate();
            if(!is_valid) { return }
            switch(this.new_question.type){
                case 1: {
                    this.items.push({...JSON.parse(JSON.stringify(this.single_choice_default)), title: this.new_question.title});
                    break;
                }
                case 2: {
                    this.items.push({...JSON.parse(JSON.stringify(this.multiple_choice_default)), title: this.new_question.title})
                    break;
                }
                case 3: {
                    this.items.push({...JSON.parse(JSON.stringify(this.range_default)), title: this.new_question.title});
                    break;
                }
            }
            this.isModalShow = false;
            this.new_question = {
                type: null,
                title: null
            };
        },
        close(){
            this.isModalShow = false;
            this.new_question = {
                type: null,
                title: null
            };
        },
        deleteQuestion(index){
            this.items.splice(index, 1)
        },
    }
}
</script>